import {
  ColorMapSelector,
  Domain,
  DomainSlider,
  GridToggler,
  InteractionInfo,
  ScaleSelector,
  ScaleType,
  Separator,
  ToggleBtn,
  Toolbar,
} from '@h5web/lib';
import { GraphUp } from 'react-bootstrap-icons';
import type { Histogram } from '../../app/models';
import { useImageConfig } from '../config';
import { CIRCLE_PROFILE_KEY, LINE_PROFILE_KEY, ZOOM_KEY } from '../utils';
import RingControl from './RingControl';

export const INTERACTIONS: InteractionInfo[] = [
  { shortcut: 'Drag', description: 'Pan' },
  { shortcut: `${LINE_PROFILE_KEY}+Drag`, description: 'Draw a profile line' },
  {
    shortcut: `${CIRCLE_PROFILE_KEY}+Drag`,
    description: 'Draw a profile circle',
  },
  { shortcut: `${ZOOM_KEY}+Drag`, description: 'Select to zoom' },
  { shortcut: 'Wheel', description: 'Zoom' },
];

interface Props {
  dataDomain: Domain;
  histogram: Histogram;
}

function ImageToolbar(props: Props) {
  const { dataDomain, histogram } = props;

  const {
    customDomain,
    setCustomDomain,
    colorMap,
    setColorMap,
    scaleType,
    setScaleType,
    showGrid,
    toggleGrid,
    showRings,
    toggleRings,
    ringColor,
    getCssRingColor,
    setRingColor,
    invertColorMap,
    toggleColorMapInversion,
    showProfile,
    toggleProfile,
  } = useImageConfig();

  return (
    <Toolbar interactions={INTERACTIONS}>
      <ToggleBtn
        value={showProfile}
        icon={GraphUp}
        onToggle={toggleProfile}
        label="Profile"
      />

      <Separator />

      <DomainSlider
        dataDomain={dataDomain}
        customDomain={customDomain}
        scaleType={scaleType}
        onCustomDomainChange={setCustomDomain}
        histogram={histogram}
      />

      <Separator />

      <ScaleSelector
        value={scaleType}
        onScaleChange={setScaleType}
        options={[
          ScaleType.Linear,
          ScaleType.Log,
          ScaleType.SymLog,
          ScaleType.Sqrt,
        ]}
      />

      <Separator />

      <ColorMapSelector
        value={colorMap}
        onValueChange={setColorMap}
        invert={invertColorMap}
        onInversionChange={toggleColorMapInversion}
      />

      <Separator />

      <RingControl
        showRings={showRings}
        toggleRings={toggleRings}
        ringColor={ringColor}
        getCssRingColor={getCssRingColor}
        setRingColor={setRingColor}
      />

      <Separator />

      <GridToggler value={showGrid} onToggle={toggleGrid} />
    </Toolbar>
  );
}

export default ImageToolbar;
