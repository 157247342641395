import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './FileBrowser.module.css';

interface Props {
  value: boolean;
  onChange: () => void;
}

function AutoloadInput(props: Props) {
  const { value, onChange } = props;

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 150, hide: 150 }}
      overlay={
        <Tooltip id="path-tooltip" className={styles.pathTooltip}>
          Auto-load most recent image
        </Tooltip>
      }
    >
      <div className={styles.autoload}>
        <label id="autoloadLabel" htmlFor="autoload">
          Follow mode
        </label>
        <input
          style={{ marginLeft: '0.5rem' }}
          id="autoload"
          name="autoload"
          type="checkbox"
          checked={value}
          onChange={onChange}
          aria-labelledby="autoloadLabel"
        />
      </div>
    </OverlayTrigger>
  );
}

export default AutoloadInput;
