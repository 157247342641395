import { Suspense, useEffect, useState } from 'react';
import { Button, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector, useDispatch } from 'react-redux';

import { setImagePath } from '../imageview/imageViewSlice';
import {
  selectDirectoryPath,
  selectRootPath,
  setDirectoryPath,
  getRootPath,
} from './fileBrowserSlice';
import type { Entry } from '../app/models';
import FilterInput from './FilterInput';
import DirectoryLoader from './DirectoryLoader';
import ErrorFallback from '../app/ErrorFallback';
import { useSWRConfig } from 'swr';
import EntryListGroupContainer from './EntryListGroupContainer';
import styles from './FileBrowser.module.css';
import { assertIsFileCreationEvent } from './guards';
import { useBrowserConfig } from './config';
import AutoloadInput from './AutoloadInput';
import { useLoadPathFromUrl, useSocketCallback } from './hooks';
import SortInput from './SortInput';
import { getParentDirectory } from './utils';

export default function FileBrowser() {
  const dispatch = useDispatch();
  const currentPath = useSelector(selectDirectoryPath);
  const rootPath = useSelector(selectRootPath);

  useLoadPathFromUrl();

  const { autoload, toggleAutoload, sortBy, setSortBy } = useBrowserConfig();

  const { cache, mutate } = useSWRConfig();

  useSocketCallback(
    'new_file_in_dir',
    (payload) => {
      assertIsFileCreationEvent(payload);
      const { dir_path: dirPath, file } = payload;
      mutate(dirPath);
      if (autoload) {
        dispatch(setImagePath(`${dirPath}/${file}`));
      }
    },
    [autoload, dispatch, mutate]
  );

  const [filter, setFilter] = useState('');
  const [numEntries, setNumEntries] = useState(0);
  const [numMatchingEntries, setNumMatchingEntries] = useState(0);

  const absPath = currentPath === `.` ? rootPath : `${rootPath}/${currentPath}`;

  function pickEntry(path: string, type: string) {
    if (type === 'dir') {
      dispatch(setDirectoryPath(path));
    } else {
      dispatch(setImagePath(path));
    }
  }

  useEffect(() => {
    if (!rootPath) {
      dispatch(getRootPath());
    }
  }, [dispatch, rootPath]);

  const goBackToParentFolder =
    currentPath !== `.`
      ? () => pickEntry(getParentDirectory(currentPath), 'dir')
      : undefined;

  return (
    <div className={styles.root}>
      <div className={styles.status}>
        <AutoloadInput value={autoload} onChange={toggleAutoload} />
        <SortInput value={sortBy} onChange={setSortBy} />
      </div>
      <div className={styles.inner}>
        <OverlayTrigger
          placement="bottom"
          transition={false}
          delay={{ show: 750, hide: 150 }}
          overlay={
            <Tooltip id="path-tooltip" className={styles.pathTooltip}>
              {absPath}
            </Tooltip>
          }
        >
          <FormControl
            className={styles.pathInput}
            value={absPath}
            aria-label="Current path"
            disabled
          />
        </OverlayTrigger>

        <FilterInput value={filter} onChange={setFilter} />

        <ErrorBoundary
          FallbackComponent={(fallbackProps) => (
            <ErrorFallback path={currentPath} {...fallbackProps}>
              {goBackToParentFolder && (
                <Button variant="info" onClick={() => goBackToParentFolder()}>
                  Go back to previous folder
                </Button>
              )}
            </ErrorFallback>
          )}
          resetKeys={[currentPath]}
          onError={
            currentPath ? () => cache.delete(`$err$${currentPath}`) : undefined
          }
        >
          <Suspense fallback={<DirectoryLoader />}>
            <EntryListGroupContainer
              path={currentPath}
              onEntryClick={(file: Entry) => {
                pickEntry(file.path, file.type);
                setFilter('');
              }}
              onDirectoryFetch={(numEntries, numMatchingEntries) => {
                setNumEntries(numEntries);
                setNumMatchingEntries(numMatchingEntries);
              }}
              filter={filter}
              sortBy={sortBy}
              goBackToParentFolder={goBackToParentFolder}
            />
          </Suspense>
        </ErrorBoundary>
      </div>

      <div className={styles.status}>
        <p>
          {filter.length > 0 && `${numMatchingEntries} of `}
          {numEntries} item{numEntries > 1 ? 's' : ''}
        </p>
        <Button
          className={styles.refreshBtn}
          variant="link"
          onClick={() => {
            mutate(currentPath);
            pickEntry(currentPath, 'dir');
          }}
        >
          Refresh
        </Button>
      </div>
    </div>
  );
}
