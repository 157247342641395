import { BoxArrowLeft, Files, Megaphone } from 'react-bootstrap-icons';
import { Panel } from './models';
import styles from './Menu.module.css';
import { removeUser } from '../login/loginSlice';
import history from '../app/history';
import { useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  activePanel: Panel | undefined;
  onTogglePanel: (panel: Panel) => void;
}

export default function Menu(props: Props) {
  const { activePanel, onTogglePanel } = props;
  const dispatch = useDispatch();

  return (
    <div className={styles.menu}>
      <Button
        variant="link"
        aria-label="Toggle file panel"
        data-active={activePanel === Panel.Files || undefined}
        onClick={() => onTogglePanel(Panel.Files)}
      >
        <Files />
      </Button>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="logout-tooltip">Log out</Tooltip>}
      >
        <Button
          className={styles.logoutBtn}
          variant="link"
          aria-label="Log out"
          onClick={() => {
            dispatch(removeUser());
            history.push('/');
          }}
        >
          <BoxArrowLeft />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={<Tooltip id="feedback-tooltip">Leave feedback</Tooltip>}
      >
        <Button
          variant="link"
          href="mailto:braggy@esrf.fr?subject=Feedback"
          target="_blank"
          aria-label="Feedback"
        >
          <Megaphone />
        </Button>
      </OverlayTrigger>
    </div>
  );
}
