import create from 'zustand';
import { persist } from 'zustand/middleware';
import { ColorMap, CustomDomain, INTERPOLATORS, ScaleType } from '@h5web/lib';
import { RingColor } from './models';

interface ImageConfig {
  customDomain: CustomDomain;
  setCustomDomain: (customDomain: CustomDomain) => void;

  colorMap: ColorMap;
  setColorMap: (colorMap: ColorMap) => void;

  invertColorMap: boolean;
  toggleColorMapInversion: () => void;

  scaleType: ScaleType;
  setScaleType: (scaleType: ScaleType) => void;

  showGrid: boolean;
  toggleGrid: () => void;

  showRings: boolean;
  toggleRings: () => void;

  ringColor: RingColor;
  getCssRingColor: (ringColor: RingColor) => string;
  setRingColor: (ringColor: RingColor) => void;

  showProfile: boolean;
  toggleProfile: () => void;
}

export const useImageConfig = create<ImageConfig>(
  persist(
    (set, get) => ({
      customDomain: [null, null],
      setCustomDomain: (customDomain: CustomDomain) => set({ customDomain }),

      colorMap: 'Viridis',
      setColorMap: (colorMap: ColorMap) => set({ colorMap }),

      invertColorMap: false,
      toggleColorMapInversion: () => {
        set((state) => ({ invertColorMap: !state.invertColorMap }));
      },

      scaleType: ScaleType.Linear,
      setScaleType: (scaleType: ScaleType) => {
        set({ scaleType });
      },

      showGrid: false,
      toggleGrid: () => set((state) => ({ showGrid: !state.showGrid })),

      showRings: false,
      toggleRings: () => set((state) => ({ showRings: !state.showRings })),

      ringColor: RingColor.Black,
      setRingColor: (ringColor: RingColor) => set({ ringColor }),
      getCssRingColor: (ringColor: RingColor) => {
        if (ringColor === RingColor.Black) {
          return '#000';
        }

        if (ringColor === RingColor.White) {
          return '#fff';
        }

        const { colorMap, invertColorMap } = get();
        const interpolator = INTERPOLATORS[colorMap];

        if (ringColor === RingColor.ColorMapMin) {
          return interpolator(invertColorMap ? 1 : 0);
        }

        if (ringColor === RingColor.ColorMapMax) {
          return interpolator(invertColorMap ? 0 : 1);
        }

        throw new Error('Invalid ring color');
      },

      showProfile: false,
      toggleProfile: () =>
        set((state) => ({ showProfile: !state.showProfile })),
    }),
    {
      name: 'braggy:image',
      version: 3,
    }
  )
);
